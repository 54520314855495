<template lang="html">
  <Sections
    v-if="$store.state.page.sections.length"
    :sections="$store.state.page.sections"
  />
</template>

<script>
import Sections from '@/sections/Sections';

export default {
  name: 'Page',
  components: {
    Sections,
  },
  watch: {
    $route() {
      this.goToRoute();
    },
    // error(err) {
    //   if (err === 'rest_no_page') {
    //     this.$router.push({ name: 'home' });
    //   }
    // },
  },
  created() {
    this.goToRoute();
  },
  methods: {
    goToRoute() {
      this.$store.commit('page/setSections', []);
      if (this.$route.name === 'home') {
        this.$store.dispatch('page/fetchPageData', 'home');
      } else if (this.$route.name === 'page') {
        this.$store.dispatch('page/fetchPageData', this.$route.params.slug);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.right-image {
}
</style>
