<template lang="html">
  <component :is="componentLoader" :initialData="section"></component>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { transformComponentName } from '@/utils';

export default {
  name: 'SectionsItem',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    componentLoader() {
      const sectionName = transformComponentName(
        this.section.acf_fc_layout,
        '_'
      );

      return defineAsyncComponent(() =>
        import(`@/sections/${sectionName}/${sectionName}.vue`)
      );
    },
  },
};
</script>
