<template lang="html">
  <!--  container: !this.$store.state.donationCandles.createCandleFormIsShow,-->
  <div
    class="sections"
    :class="{
      container: this.$route.path !== '/',
      'sections--open-donation-form':
        $store.state.donationProductForm.formStepsIsShow,
      'sections--page-info': isPageInfo,
    }"
  >
    <SectionsItem
      :section="section"
      v-for="section in sections"
      :key="section.unique_id"
    />
  </div>
</template>

<script>
import SectionsItem from '@/sections/SectionsItem.vue';

export default {
  name: 'Sections',
  components: {
    SectionsItem,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isPageInfo() {
      const find = this.sections.find(({ acf_fc_layout }) =>
        ['page_info', 'organ'].includes(acf_fc_layout)
      );

      return find ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.sections {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &--open-donation-form {
    @media (max-width: $largeMobile) {
      :deep(.back-button),
      :deep(.heading-and-description) {
        display: none;
      }
    }
  }

  &--page-info {
    @media (min-width: $largeMobile) {
      justify-content: center;
    }
  }
}
</style>
