<template lang="html">
  <SingleCandle
    v-if="typeCandle === 'lit'"
    typeSection="single-page"
    :info="singlePageData"
  />
</template>

<script>
import SingleCandle from '@/sections/SingleCandle/SingleCandle.vue';

export default {
  name: 'CandleItem',
  components: {
    SingleCandle,
  },
  props: {
    singlePageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    typeCandle() {
      return this.singlePageData.status;
    },
  },
  created() {
    this.$store.commit('donationCandles/setSingleCandle', this.singlePageData);
  },
  beforeUnmount() {
    this.$store.dispatch('singlePage/reset');
  },
};
</script>
