<template lang="html">
  <template v-if="singlePageData.ID">
    <SinglePageCandle
      v-if="postType === 'candles'"
      :singlePageData="singlePageData"
    />
  </template>
</template>

<script>
import SinglePageCandle from '@/pages/SinglePage/SinglePageCandle';

export default {
  name: 'SinglePage',
  components: { SinglePageCandle },
  watch: {
    $route() {
      this.goToRoute();
    },
    singlePageData(val) {
      if (val.code === 'not_found') {
        this.$store.commit('page/setErrorCode', 'rest_no_page');
      }
    },
  },
  computed: {
    singlePageData() {
      return this.$store.state.singlePage.singlePageData;
    },
    postType() {
      let postType = '';
      if (this.$route.params.slug === this.$store.state.global.slugs.candles) {
        postType = 'candles';
      }
      return postType;
    },
  },
  created() {
    this.goToRoute();
  },
  methods: {
    goToRoute() {
      if (this.postType && this.$route.params.id) {
        this.$store.dispatch('singlePage/fetchSinglePageData', {
          postType: this.postType,
          postId: this.$route.params.id,
        });
      } else if (this.$route.params.id) {
        this.$store.commit('page/setErrorCode', 'rest_no_page');
      }
    },
  },
};
</script>
